import styled from "styled-components"

export const Container = styled.header`
  .Content {
    padding: 2rem 0;
  }
`

export const Logo = styled.div`
  img {
    max-width: 220px;
  }

`

export const BoxMenuMobile = styled.div`
  display: none;
  font-family: "Gosha";

  button.primaryButton {
    margin: 1rem auto 0;
    width: 100%;
    color: #f2f2f2 !important;
  }

  a.happyButton {
    width: 100%;
    color: #3eb7e4 !important;
    border-bottom: none !important;
  }

  @media screen and (max-width: 770px) {
    display: block;
  }
`

export const MenuDesktop = styled.div`
  display: block;

  @media screen and (max-width: 770px) {
    display: none;
  }
`

export const MenuLinks = styled.nav`
  ul {
    display: flex;
    list-style: none;
    align-items: center;

    li {
      padding: 0 1rem;
    }

    a {
      text-decoration: none;
      font-family: "Gosha";
      padding-bottom: 0.5rem;
      border-bottom: 3px solid #ffffff;
      transition: all ease-out 0.4s;
      color: var(--blue-dark);

      &:hover {
        border-bottom: 3px solid var(--blue-dark);
      }
    }

    a.primaryButton {
      &:hover {
        border-bottom: 3px solid #fff;
      }
    }

    a.happyButton {
      color: #3eb7e4;
      font-weight: bold;

      &:hover {
        border-bottom: 3px solid #3eb7e4;
      }
    }
  }
`
