import styled from "styled-components"

export const FooterSection = styled.footer`
  background-color: var(--blue-dark);
  padding: 3rem 0 1rem;
  color: #ffffff;

  img.logo-branca,
  p {
    margin-bottom: 2rem;
  }

  form {
    width: 60%;
    position: relative;
  }

  .policy-links {
    margin-top: 1rem;
  }

  .policy-links li a {
    text-decoration: underline;
  }

  input {
    width: 100%;
    height: 40px;
    color: #ffffff;
    background: #526271;
    border: none;
    padding-left: 1rem;
  }

  input::placeholder {
    color: #ffffff;
    font-weight: bold;
  }

  button[type="submit"] {
    background: transparent;
    position: absolute;
    border: none;
    right: 0.5rem;
    top: 0.8rem;
  }

  a {
    color: #ffffff;
    text-decoration: none;
  }

  ul {
    display: flex;
    flex-direction: row;
    list-style: none;
    padding: 0;
    flex-wrap: wrap;
    li {
      margin-right: 1rem;
      margin-bottom: 1rem;
    }
  }

  .socials {
    display: flex;
    align-items: center;
    font-weight: bold;
    img:first-child {
      margin-left: 1rem;
    }
    img {
      width: 30px;
      margin-right: 0.5rem;
    }
  }
`
