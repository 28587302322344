import React from "react"
import { useForm } from "react-hook-form"
import { Box, BoxColumns } from "../Sections/styles"
import { FooterSection } from "./styles"
import swal from "sweetalert"

import logoBranca from "../../../static/logo-branca.svg"
import facebook from "../../../static/icon-facebook.svg"
import linkedin from "../../../static/icon-linkedin.svg"
import instagram from "../../../static/icon-instagram.svg"
import arrowButton from "../../../static/arrow-button.svg"
import { Link } from "gatsby"

export function Footer() {
  //Configs de UTM's
  const currentUrl = typeof window !== "undefined" ? window.location.href : ""

  const getUrlParamByName = (name, url) => {
    if (!url) url = currentUrl
    name = name.replace(/[[\]]/g, "\\$&")
    var regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)")
    var results = regex.exec(url)

    if (!results) return null
    if (!results[2]) return ""

    return decodeURIComponent(results[2].replace(/\+/g, " "))
  }

  const utmSourceRef =
    getUrlParamByName("utm_source") !== null
      ? getUrlParamByName("utm_source")
      : ""
  const utmMediumRef =
    getUrlParamByName("utm_medium") !== null
      ? getUrlParamByName("utm_medium")
      : ""
  const utmCampaignRef =
    getUrlParamByName("utm_campaign") !== null
      ? getUrlParamByName("utm_campaign")
      : ""
  const utmTermRef =
    getUrlParamByName("utm_term") !== null ? getUrlParamByName("utm_term") : ""
  const utmContentRef =
    getUrlParamByName("utm_content") !== null
      ? getUrlParamByName("utm_content")
      : ""

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm()

  const onSubmit = data =>
    swal(
      "Email enviado!",
      "Obrigado, nós recebemos sua mensagem, em breve entraremos em contato.",
      "success"
    )

  return (
    <FooterSection id="footer">
      <div className="Content">
        <BoxColumns widthFraction="1fr 1fr">
          <Box>
            <img src={logoBranca} alt="Riversoft" className="logo-branca" />
            <p>
              <strong>
                Inscreva-se em nossa Newsletter,
                <br />
              </strong>
              assim você receberá notícias e informações atualizadas!
            </p>
            <form id="form-newsletter" onSubmit={handleSubmit(onSubmit)}>
              <input
                type="email"
                name="email"
                id="email"
                placeholder="E-mail*"
                {...register("email", { required: true })}
                style={{ marginBottom: "1rem" }}
              />
              {errors.email?.type === "required" && (
                <p className="error-text"> Não pode ser vazio. </p>
              )}
              <input
                type="hidden"
                name="utm_source"
                {...register("utm_source", { required: false })}
                value={utmSourceRef}
              />
              <input
                type="hidden"
                name="utm_medium"
                {...register("utm_medium", { required: false })}
                value={utmMediumRef}
              />
              <input
                type="hidden"
                name="utm_campaign"
                {...register("utm_campaign", { required: false })}
                value={utmCampaignRef}
              />
              <input
                type="hidden"
                name="utm_term"
                {...register("utm_term", { required: false })}
                value={utmTermRef}
              />
              <input
                type="hidden"
                name="utm_content"
                {...register("utm_content", { required: false })}
                value={utmContentRef}
              />
              <input
                type="hidden"
                name="url_pagina"
                {...register("url_pagina", { required: false })}
                value={currentUrl}
              />
              <button type="submit">
                <img src={arrowButton} alt="Riversoft" />
              </button>
            </form>
            <ul className="policy-links">
              <li>
                <Link to="/politicadeprivacidade">
                  Políticas de privacidade
                </Link>
              </li>
              <li>
                <Link to="/termosdeuso">Termos de uso</Link>
              </li>
            </ul>
          </Box>
          <Box>
            <ul style={{ marginBottom: "2rem" }}>
              <li>
                <a href="#solutions" rel="noreferrer">
                  Soluções
                </a>
              </li>
              <li>
                <a href="#about" rel="noreferrer">
                  A Riversoft
                </a>
              </li>
              <li>
                <a href="#partners" rel="noreferrer">
                  Parceiros
                </a>
              </li>
              {/* <li>
                <a href="#">Blog</a>
              </li> */}
            </ul>
            <p>
              Nós temos nosso próprio sistema de transferência de arquivos, o
              STCP, para integração de dados, envio e gerenciamento de
              documentos com segurança e de acordo com a LGPD.
            </p>

            <div className="socials">
              Conheça nossas redes sociais:{" "}
              <a
                href="https://www.facebook.com/riversoftbrasil"
                target="_blank"
                rel="noreferrer"
              >
                <img src={facebook} alt="Riversoft - ícone facebook" />
              </a>
              <a
                href="https://www.linkedin.com/company/riversoftbrasil/"
                target="_blank"
                rel="noreferrer"
              >
                <img src={linkedin} alt="Riversoft - ícone linkedin" />
              </a>
              <a
                href="https://www.instagram.com/riversoft_brasil/"
                target="_blank"
                rel="noreferrer"
              >
                <img src={instagram} alt="Riversoft - ícone instagram" />
              </a>
            </div>
          </Box>
        </BoxColumns>
      </div>
      <div className="Content">
        <BoxColumns
          widthFraction="1fr 1fr"
          style={{ marginTop: "2rem", width: "100%" }}
        >
          <Box width="100%">
            <p>Todos os direitos reservados</p>
          </Box>
        </BoxColumns>
      </div>
    </FooterSection>
  )
}
