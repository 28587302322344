import React from "react"
import { slide as MenuMobile } from "react-burger-menu"
import {
  Container,
  Logo,
  MenuDesktop,
  BoxMenuMobile,
  MenuLinks,
} from "./styles"
// import imgLogo from "../../../static/logo.svg"
import imgLogo from "../../../static/logo-20.png"
import { Link } from "gatsby"

export function Header({ onOpenContactModal }) {
  const styles = {
    bmBurgerButton: {
      position: "absolute",
      width: "36px",
      height: "30px",
      right: "36px",
      top: "30px",
    },
    bmBurgerBars: {
      background: "#373a47",
      height: "12%",
      borderRadius: "30px",
    },
    bmBurgerBarsHover: {
      background: "#a90000",
    },
    bmCrossButton: {
      height: "24px",
      width: "24px",
    },
    bmCross: {
      background: "#bdc3c7",
    },
    bmMenuWrap: {
      position: "fixed",
      height: "100%",
    },
    bmMenu: {
      background: "#f2f2f2",
      padding: "2.5em 1.5em 0",
      fontSize: "1.15em",
    },
    bmMorphShape: {
      fill: "#071E34",
    },
    bmItemList: {
      color: "#071E34",
      padding: "0.8em",
    },
    bmItem: {
      display: "block",
      color: "#071E34",
      textAlign: "center",
      padding: "1.5rem 0",
      borderBottom: "0.5px solid #071e3421",
      textDecoration: "none",
      width: "100%",
    },
    bmOverlay: {
      background: "rgba(0, 0, 0, 0.3)",
    },

  }

  return (
    <Container>
      <BoxMenuMobile>
        <MenuMobile styles={styles}>
          <a className="menu-item" href="/#solutions">
            Soluções
          </a>
          <a className="menu-item" href="/#about">
            A Riversoft
          </a>
          <a className="menu-item" href="/#partners">
            Parceiros
          </a>
          <a className="menu-item" href="/blog">
            Blog
          </a>
          <a className="happyButton" href="https://20anos.riversoft.com.br" target="blank">
            20 anos
          </a>
          <a className="primaryButton" href="/#form-contact">
            Contato
          </a>
        </MenuMobile>
      </BoxMenuMobile>

      <div className="Content">
        <Link to="/">
          <Logo className="Logo">
            <img src={imgLogo} alt="Riversoft" />
          </Logo>
        </Link>
        <MenuDesktop>
          <MenuLinks>
            <ul>
              <li>
                <a href="/#solutions">Soluções</a>
              </li>
              <li>
                <a href="/#about">A Riversoft</a>
              </li>
              <li>
                <a href="/#partners">Parceiros</a>
              </li>
              <li>
                <a href="/blog">Blog</a>
              </li>
              <li>
                <a className="happyButton" href="https://20anos.riversoft.com.br" target="blank">20 anos</a>
              </li>
              <li>
                <a className="primaryButton" href="/#form-contact">
                  Contato
                </a>
              </li>
            </ul>
          </MenuLinks>
        </MenuDesktop>
      </div>
    </Container>
  )
}
