import { createGlobalStyle } from "styled-components"

import fontGoshaNormal from "./fonts/GoshaSansRegular.woff"

export const GlobalStyle = createGlobalStyle`
    :root{
        --blue-dark: #071E34;
        --blue-light: #19afd4;
    }

    @font-face{
        font-family: 'Gosha';
        src: url(${fontGoshaNormal});
    }
    
    @font-face{
        font-family: 'Open Sans';
        src: url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;600;700&display=swap');
    }

    *{
        margin: 0;
        padding: 0;
        box-sizing: 0
    }

    html{
        @media (max-width: 1080px){
            font-size: 93.75%
        }

        @media (max-width: 720px){
            font-size: 87.5%
        }
        scroll-behavior: smooth;
    }
    
    body{
        -webkit-font-smoothing: antialiased;
        color: var(--blue-dark);
        font-family: 'Open Sans', sans-serif;
        overflow-x: hidden;
    }

    button{
        cursor: pointer
    }

    :focus{
        outline: none;
    }
    

    [disabled]{
        opacity: 0.6;
        cursor: not-allowed;
    }

    .fadeIn {
        animation: fadeIn 1s linear;
    }
    
    @keyframes fadeIn {
        from{ 
            opacity: 0;
        }
        to{ 
            opacity: 1; 
        }
    }

    .hide{
        visibility: hidden;
    }

    .Content{
        width: 80%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin: 0 auto;
    }

    .primaryButton{
        color: #ffffff!important;
        padding: 0.5rem 2rem;
        background-color: var(--blue-dark);
        border: none;
        border-radius: 19px;
        display: flex;
        align-items: center;
        font-weight: bold;
        transition: all ease-out 0.4s;
        width: fit-content;
        text-decoration: none;

        img {
        margin-left: 1rem;
        width: 12px;
        }

        &:hover {
            background: var(--blue-light);
        }
    }
    
    .secondaryButton{
        padding: 0.5rem 2rem;
        border: 1px solid var(--blue-dark);
        color: var(--blue-dark);
        background-color: transparent;
        border-radius: 19px;
        display: flex;
        align-items: center;
        transition: all ease-out 0.4s;
        font-weight: bold;
        transition: all ease-out 0.4s;
        text-decoration: none;
        width: fit-content;

        img {
        margin-left: 1rem;
        width: 12px;
        }

        &:hover {
            background-color: var(--blue-dark);
            color: #ffffff;
        }
    }

    .thirdButton{
        color: #ffffff;
        padding: 0.5rem 2rem;
        border: 1px solid #FFFFFF;
        background-color: transparent;
        border-radius: 19px;
        display: flex;
        align-items: center;
        font-weight: bold;
        transition: all ease-out 0.4s;
        text-decoration: none;
        width: fit-content;

        img {
        margin-left: 1rem;
        width: 12px;
        }

        &:hover {
            background-color: #FFFFFF;
            color: var(--blue-dark);

            img {
                filter: brightness(0.2);
            }
        }
    }

    .strong{
        font-weight: 700;
    }

    .medium{
        font-weight: 600;
    }

    .react-modal-overlay{
        background: rgba(0,0,0,0.5);
        position: fixed;
        top: 0;
        bottom: 0;
        right: 0;
        left: 0;

        display: flex;
        align-items: center;
        justify-content: center;
    }
    
    .react-modal-content{
        width: 100%;
        max-width: 576px;
        background: #f2f2f2;
        padding: 3rem;
        position: relative;
        border-radius: 0.25rem;
    }

    .ReactModal__Overlay {
        opacity: 0;
        transition: opacity 0400ms ease-in-out;
    }

    .ReactModal__Overlay--after-open{
        opacity: 1;
    }

    .ReactModal__Overlay--before-close{
        opacity: 0;
    }


    .swal-text{
        text-align: center;
    }

    .error-text{
        color: #bd7575bf;
        display: block;
        margin-top: -0.7rem!important;
        width: 100%;
    }

    #rd-section-ksevpgl4{
        cursor: pointer!important;
    }

    /* Adaptações para exitPopup Rd Station */
    #rd-form-ksevpgla{
        display: none!important;
    }

    
`
