import styled from "styled-components"

import imgBars from "../../../static/bars.png"
import imgBgLight from "../../../static/bg-light.png"
import imgTop from "../../../static/bg-top.jpg"
import imgCircleArrowWhite from "../../../static/arrow-circle-white.svg"

export const PopUpAviso = styled.section`
  position: absolute;
  top: 0;
  width: 100vw;
  height: 100vh;
  align-items: center;
  justify-content: center;
  background: rgba(0, 0, 0, 0.8);
  font-family: "Gosha", sans-serif;
  text-transform: uppercase;
  line-height: 1.3rem;
  z-index: 1001;

  .open {
    display: flex;
  }

  .closed {
    display: none !important;
  }

  .wrapper {
    position: relative;
    display: grid;
    grid-template-columns: 1fr;
    max-width: 600px;
    min-width: 600px;
    background: url(${imgBgLight}) center center/cover no-repeat;
    padding: 3rem 0;

    .box {
      background: rgb(255, 255, 255) url(${imgBars}) right center/30% no-repeat;
      padding: 2rem 4rem;
    }

    .closeIcon {
      position: absolute;
      cursor: pointer;
      top: 1rem;
      right: 1rem;
    }

    .dividerUp {
      width: 85%;
      margin: 0 auto 3rem;
      height: 2px;
      background: rgb(255, 255, 255);
    }

    .dividerDown {
      width: 80%;
      margin: 3rem auto 0;
      height: 2px;
      background: rgb(255, 255, 255);
    }
  }

  @media (max-width: 630px) {
    .wrapper {
      max-width: 90%;
      min-width: 90%;
    }

    .box {
      padding: 1rem !important;
    }
  }
`

export const BannerSection = styled.section`
  display: flex;
  background: var(--blue-dark);
  color: rgb(255, 255, 255);
  padding: 0.5rem 2rem;

  p {
    max-width: 100%;
    margin: 0 auto;
    text-align: center;
  }

  p strong.link {
    text-decoration: underline;
    cursor: pointer;
  }
`

export const FirstSection = styled.section`
  background: url(${imgTop}) top center/cover no-repeat;
  padding: 4rem 0 6rem;

  h1 {
    font-family: "Gosha", sans-serif;
  }
`

export const SecondSection = styled.section`
  position: relative;
  overflow: hidden;

  background: url(${({ bgImage }) => bgImage}) top left/cover no-repeat;
  padding: 24rem 0 8rem;

  h2 {
    font-size: 3rem;
    font-family: "Gosha", sans-serif;
    margin-bottom: 1rem;
  }

  p {
    margin-bottom: 1rem;
  }

  .rec-slider-container {
    @media screen and (max-width: 770px) {
      margin: 0;
    }
  }

  .rec.rec-arrow {
    position: absolute;
    bottom: -4rem;
    font-size: 0;
    border: none;
    box-shadow: none;

    @media screen and (max-width: 770px) {
      bottom: -5rem;
    }
  }

  .rec.rec-arrow-right {
    left: 10rem;
    background: url(${imgCircleArrowWhite}) center center/contain no-repeat;
    transform: rotateY(180deg);
    &:hover,
    &:focus {
      background-color: inherit;
      box-shadow: none;
    }

    @media screen and (max-width: 770px) {
      left: 14rem;
    }
  }
  .rec.rec-arrow-left {
    left: 3rem;
    background: url(${imgCircleArrowWhite}) center center/contain no-repeat;
    &:hover,
    &:focus {
      background-color: inherit;
      box-shadow: none;
    }
  }

  .bg-img {
    position: absolute;
    left: 0;
    top: 0;
    z-index: -1;
    opacity: 0;
    transition: opacity ease-in-out 0.8s;
  }

  .show-img {
    opacity: 1;
  }

  .hide-img {
    opacity: 0;
  }

  .normal-carousel {
    display: block;

    @media screen and (max-width: 770px) {
      display: none;
    }
  }

  .responsive-carousel {
    display: none;

    @media screen and (max-width: 770px) {
      display: block;
    }
  }

  @media screen and (max-width: 770px) {
    padding: 2rem 0 8rem !important;
  }
`

export const CardServices = styled.div`
  position: relative;
  p {
    font-family: "Gosha";
    font-weight: bold;
    font-size: 1.8rem;
    color: #ffffff;
    position: absolute;
    top: 55%;
    max-width: 210px;
    padding: 0 3rem;
  }

  .card-slide {
    cursor: pointer;
    max-width: 100%;

    img {
      box-shadow: 1px 1px 8px rgb(0 0 0 / 80%);
      transition: all ease-in-out 0.4s;
    }

    &:hover {
      img {
        opacity: 0.8;
      }
    }
  }

  @media screen and (max-width: 1500px) {
    p {
      padding: 0 1rem !important;
      font-size: 1.4rem;
      max-width: 10%;
    }

    .card-slide {
      max-width: 95%;
    }
  }

  @media screen and (max-width: 770px) {
    p {
      padding: 0 2rem !important;
      font-size: 2rem;
    }
  }
`

export const ThirdSection = styled.section`
  position: relative;
  padding: 5rem 0 1rem;

  h2 {
    font-size: 3rem;
    font-family: "Gosha", sans-serif;
    margin-bottom: 1rem;
  }

  .img-third-section {
    max-width: 67%;
    position: absolute;
    right: 0;
  }

  .Content {
    flex-direction: column;
  }

  .logo {
    max-width: 100%;
  }

  @media screen and (max-width: 770px) {
    .img-third-section {
      max-width: 100%;
      position: relative;
      margin-bottom: 2rem;
    }

    .logo {
      max-width: 80%;
      margin: 0 auto 2rem;
    }
  }
`

export const FourthSection = styled.section`
  background-color: var(--blue-dark);
  position: relative;
  padding: 4rem 0;
  color: #ffffff;

  h2 {
    font-size: 3rem;
    font-family: "Gosha", sans-serif;
    margin-bottom: 1rem;
  }
`

export const FifthSection = styled.section`
  position: relative;
  padding: 4rem 0;

  .Content {
    flex-direction: column;
  }

  h2 {
    font-size: 3rem;
    font-family: "Gosha", sans-serif;
    margin-bottom: 1rem;
  }

  h2,
  address,
  p {
    margin-top: 1.2rem;
  }

  .box-contact-form {
    background-color: var(--blue-dark);
    color: #ffffff;
    padding: 2rem;

    h3 {
      font-size: 3rem;
      font-family: "Gosha", sans-serif;
      margin-bottom: 1rem;
    }

    p {
      margin-bottom: 0.5rem;
      margin-top: 0;
    }

    p + p {
      margin-bottom: 1.5rem;
    }

    input {
      padding: 0 1rem;
      height: 2rem;
      width: 90%;
      margin-bottom: 1rem;
    }

    input::placeholder,
    textarea::placeholder {
      color: var(--blue-dark);
      font-family: "Gosha", sans-serif;
      opacity: 0.8;
    }

    textarea {
      font-family: "Open sans", sans-serif;
      width: 90%;
      margin-bottom: 1rem;
      padding: 1rem;
    }
  }

  @media screen and (max-width: 770px) {
    iframe {
      width: 100% !important;
    }
  }
`

export const BoxColumns = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: ${({ widthFraction }) => widthFraction};
  gap: 2rem;

  @media screen and (max-width: 1500px) {
    grid-template-columns: ${({ largeScreenwidthFraction }) =>
      largeScreenwidthFraction}!important;
  }

  @media screen and (max-width: 770px) {
    grid-template-columns: ${({ mediumScreenwidthFraction }) =>
      mediumScreenwidthFraction}!important;
  }

  @media screen and (max-width: 440px) {
    grid-template-columns: 1fr !important;
  }
`

export const Box = styled.div`
  width: ${({ width }) => width};
`

export const BoxTextRight = styled.div`
  display: flex;
  justify-content: flex-end;
  width: 100%;

  h1,
  p {
    color: ${({ color }) => color};
  }
`

export const Text = styled.div`
  width: ${({ width }) => width};
  color: ${({ color }) => color};

  h1 {
    font-size: 3rem;
    margin-bottom: 1rem;
  }

  p {
    margin-bottom: 1.3rem;
    line-height: 1.5;
  }

  @media screen and (max-width: 770px) {
    width: 100% !important;
  }
`

export const Services = styled.div`
  width: 85%;
  z-index: 10;
  margin-bottom: 4rem;

  @media screen and (max-width: 770px) {
    width: 100% !important;
  }

  @media screen and (max-width: 400px) {
    width: 100% !important;
  }
`

export const Card = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  text-align: center;
  background: var(--blue-dark);
  padding: 3rem 1rem;
  color: #ffffff;
  max-width: 300px;
  margin: 0 auto;

  p.strong {
    margin-bottom: 1rem;
    max-width: 70%;
  }

  img {
    margin-bottom: 2.3rem;
    height: 45px;
  }
`
